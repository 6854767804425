import { makeStyles, TextField } from '@material-ui/core';
import { Container, Grid } from '@material-ui/core';
import contacto from '../../images/Contacto.png';
import Button from '@material-ui/core/Button';
import React from 'react';
import Layout from '../../layout';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width:'90%'
        },
    },
    imgStyle:{
        width:'80%',
        [theme.breakpoints.down('md')]:{
            width:'100%',
        }
    },
    inputName: {
/*         width:'97% !important',
        [theme.breakpoints.down('sm')]: {
            width:'97% !important',
            marginTop: '2rem'
        }, */

    },
    inputLastName: {
        /* width:'49% !important',
        [theme.breakpoints.down('sm')]: {
            width:'97% !important'
        }, */
    },
    button:{
        margin:'0.8rem',
        [theme.breakpoints.down('sm')]:{
            width:'97%',
            margin: '1rem 0.5rem'
        }
    }
  }));

const Contacto = () => {
    
    const classes = useStyles();
    
    return (
        <Layout>
            <Container>
                <Grid 
                style={{ height:'90vh'}}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center">
                    <Grid item xs={12}>
                        <h1 className="main-title">Contacto</h1>
                    </Grid>
                    <Grid item md={6} xs={12} className={classes.infoContainer}>
                        <img src={contacto} alt="Contacto" className={classes.imgStyle}/>
                        <a  href="https://www.google.com.mx/maps/place/Marconi+270,+Revoluci%C3%B3n,+44400+Guadalajara,+Jal./@20.6642197,-103.328387,17z/data=!3m1!4b1!4m5!3m4!1s0x8428b2264d9a3989:0x28370827147cc88!8m2!3d20.6642147!4d-103.3261983" target="_blank">
                            <p style={{ color:'#003A7A' }}>D: Marconi #270, Revolución, CP 44400, Guadalajara, Jal.</p>
                        </a> 
                        <p className="text-gray">T: <a className="text-gray" href="tel:3336186450 " >33 3618 6450</a> / <a href="tel:3617924636" className="text-gray">36 1792 4636</a></p>
                        <a href="mailto:ventas@diblaga.com.mx">
                            <p className="text-gray">E: ventas@diblaga.com.mx</p>
                        </a>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <form className={classes.root} noValidate>
                            <div>
                                <Grid item container>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                        label="Nombre" 
                                        name="name" />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                    <TextField
                                    className={classes.inputLastName}
                                    label="Apellido" 
                                    name="lastname" />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField
                                        style={{ width: '95%' }}
                                        label="Correo" 
                                        name="email" />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField
                                        style={{ width: '95%' }}
                                        id="outlined-multiline-static"
                                        label="Mensaje"
                                        multiline
                                        rows={4}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{textAlign:'right', marginTop: '1rem'}}>
                                <Button variant="outlined" color="primary" className={classes.button}>Enviar</Button>
                            </div>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    );
}

export default Contacto;